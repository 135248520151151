/* eslint comment-density/comment-density: 0 */
// Это кастомные иконки, которые вырезаются из Figma
// но можно так же использовать любую svg.
// Расширение у файл JS а не TS
// потому что тайпскрипт не понимает нотации -!svg-vue3-loader!
// и ругается на то, что такого модуля нет.
// Прежде чем добавлять новую иконку, проверьте
// Есть она уже или нет, чтобы не плодить дубли
import OdinIcon from 'src/assets/icons/odin.svg?component';
import OdinSquareIcon from 'src/assets/icons/odin-square.svg?component';
import OpenChatIcon from 'src/assets/icons/open-chat.svg?component';
import BuildingIcon from 'src/assets/icons/building.svg?component';
import ChatIcon from 'src/assets/icons/chat.svg?component';
import DashboardIcon from 'src/assets/icons/dashboard.svg?component';
import OpenBookIcon from 'src/assets/icons/open-book.svg?component';
import SearchIcon from 'src/assets/icons/search.svg?component';
import UserNoPhotoIcon from 'src/assets/icons/user-no-photo.svg?component';
import LifeRingIcon from 'src/assets/icons/life-ring.svg?component';
import ArchiveIcon from 'src/assets/icons/archive.svg?component';
import CheckSquareIcon from 'src/assets/icons/check-square.svg?component';
import GradeIcon from 'src/assets/icons/grade.svg?component';
import PencilIcon from 'src/assets/icons/pencil.svg?component';
import LaptopIcon from 'src/assets/icons/laptop.svg?component';
import OfflineActivityIcon from 'src/assets/icons/offline-activity.svg?component';
import ArrowLeftIcon from 'src/assets/icons/arrow-left.svg?component';
import ArrowRightIcon from 'src/assets/icons/arrow-right.svg?component';
import CalendarCheckIcon from 'src/assets/icons/calendar-check.svg?component';
import CohortsIcon from 'src/assets/icons/cohorts.svg?component';
import DisciplineIcon from 'src/assets/icons/discipline.svg?component';
import GroupIcon from 'src/assets/icons/group.svg?component';
import ProgramIcon from 'src/assets/icons/program.svg?component';
import ProgramNoPhotoIcon from 'src/assets/icons/education-program-no-photo.svg?component';
import PeopleIcon from 'src/assets/icons/people.svg?component';
import CopyIcon from 'src/assets/icons/copy.svg?component';
import CopyContentIcon from 'src/assets/icons/copy-content.svg?component';
import MoveDirectoryIcon from 'src/assets/icons/move-directory.svg?component';
import AddMaterialIcon from 'src/assets/icons/add-material.svg?component';
import AddFolderIcon from 'src/assets/icons/add-folder.svg?component';
import MoveFolderIcon from 'src/assets/icons/move-folder.svg?component';
import RefreshIcon from 'src/assets/icons/refresh.svg?component';
import ActivityPlanIcon from 'src/assets/icons/activity-plan.svg?component';
import DisciplinePlanIcon from 'src/assets/icons/discipline-plan.svg?component';
import DocumentIcon from 'src/assets/icons/document.svg?component';
import FileIcon from 'src/assets/icons/file.svg?component';
import FolderIcon from 'src/assets/icons/folder.svg?component';
import ImageIcon from 'src/assets/icons/image.svg?component';
import PresentationIcon from 'src/assets/icons/presentation.svg?component';
import SurveyIcon from 'src/assets/icons/survey.svg?component';
import TestIcon from 'src/assets/icons/test.svg?component';
import TestTrainIcon from 'src/assets/icons/test-train.svg?component';
import VideoIcon from 'src/assets/icons/video.svg?component';
import NotSupportedFileIcon from 'src/assets/icons/not-supported-file.svg?component';
import DownloadIcon from 'src/assets/icons/download.svg?component';
import WarningIcon from 'src/assets/icons/warning.svg?component';
import TrashIcon from 'src/assets/icons/trash.svg?component';
import BankIcon from 'src/assets/icons/bank.svg?component';
import OpenNewIcon from 'src/assets/icons/open-new.svg?component';
import SuccessIcon from 'src/assets/icons/success.svg?component';
import EditIcon from 'src/assets/icons/edit.svg?component';
import CloseIcon from 'src/assets/icons/close-icon-white.svg?component';
import Close2Icon from 'src/assets/icons/close.svg?component';
import AcceptCallIcon from 'src/assets/icons/accept-call.svg?component';
import DeclineCallIcon from 'src/assets/icons/decline-call.svg?component';
import AddCircleDottedIcon from 'src/assets/icons/add-circle-dotted.svg?component';
import AddCircleIcon from 'src/assets/icons/add-circle.svg?component';
import UserCheckIcon from 'src/assets/icons/user-check.svg?component';
import DateIcon from 'src/assets/icons/date.svg?component';
import DisclosureIcon from 'src/assets/icons/disclosure.svg?component';
import PhotoIcon from 'src/assets/icons/photo.svg?component';
import DeleteCircleIcon from 'src/assets/icons/delete-circle.svg?component';
import CityIcon from 'src/assets/icons/city.svg?component';
import LinkIcon from 'src/assets/icons/link.svg?component';
import EditLineIcon from 'src/assets/icons/edit-line.svg?component';
import DisclosureDownIcon from 'src/assets/icons/disclosureDown.svg?component';
import BanIcon from 'src/assets/icons/ban.svg?component';
import OpenPlayIcon from 'src/assets/icons/open-play.svg?component';
import LayoutIcon from 'src/assets/icons/layout.svg?component';
import YandexIcon from 'src/assets/icons/yandex.svg?component';
import GoogleIcon from 'src/assets/icons/google.svg?component';
import University2035Icon from 'src/assets/icons/university2035.svg?component';
import VkIcon from 'src/assets/icons/vkid.svg?component';
import HelpIcon from 'src/assets/icons/help.svg?component';
import ListIcon from 'src/assets/icons/list.svg?component';
import SubdivisionIcon from 'src/assets/icons/subdivision.svg?component';
import OutIcon from 'src/assets/icons/out.svg?component';
import UserMinusIcon from 'src/assets/icons/user-minus.svg?component';
import UserIcon from 'src/assets/icons/user.svg?component';
import UserListIcon from 'src/assets/icons/user-list.svg?component';
import MenuIcon from 'src/assets/icons/menu.svg?component';
import GridIcon from 'src/assets/icons/grid.svg?component';
import PadlockIcon from 'src/assets/icons/padlock.svg?component';
import CommentIcon from 'src/assets/icons/comment.svg?component';
import CalendarIcon from 'src/assets/icons/calendar.svg?component';
import FinishIcon from 'src/assets/icons/finish.svg?component';
import GbIcon from 'src/assets/icons/gb.svg?component';
import AnaliticsIcon from 'src/assets/icons/analitics.svg?component';
import DragIcon from 'src/assets/icons/drag.svg?component';
import CheckIcon from 'src/assets/icons/check.svg?component';
import FailIcon from 'src/assets/icons/fail.svg?component';
import TimeIcon from 'src/assets/icons/time.svg?component';
import InarchiveIcon from 'src/assets/icons/inarchive.svg?component';
import UnarchiveIcon from 'src/assets/icons/unarchive.svg?component';
import ArchivedIcon from 'src/assets/icons/archived.svg?component';
import InActiveIcon from 'src/assets/icons/in-active.svg?component';
import ChatMessageIcon from 'src/assets/icons/chat-message.svg?component';
import NewDottedIcon from 'src/assets/icons/new-dotted.svg?component';
import FilterIcon from 'src/assets/icons/filter.svg?component';
import LockIcon from 'src/assets/icons/lock.svg?component';
import CloseCircleIcon from 'src/assets/icons/close-circle.svg?component';
import OpenFullScreenIcon from 'src/assets/icons/open-full-screen.svg?component';
import HeartIcon from 'src/assets/icons/heart.svg?component';
import ClockIcon from 'src/assets/icons/clock.svg?component';
import VisibleIcon from 'src/assets/icons/visible.svg?component';
import NotVisibleIcon from 'src/assets/icons/not-visible.svg?component';
import ThumbsUpIcon from 'src/assets/icons/thumbs-up.svg?component';
import StarIcon from 'src/assets/icons/star.svg?component';
import AlertErrorIcon from 'src/assets/icons/alert-error.svg?component';
import MegaphoneIcon from 'src/assets/icons/megaphone.svg?component';
import AcceptCall2Icon from 'src/assets/icons/accept-call-2.svg?component';
import DocIcon from 'src/assets/icons/doc.svg?component';
import InfoIcon from 'src/assets/icons/info.svg?component';
import ClosedDivisionIcon from 'src/assets/icons/locked.svg?component';
import SettingsIcon from 'src/assets/icons/settings.svg?component';
import Settings2Icon from 'src/assets/icons/settings2.svg?component';
import GeolocIcon from 'src/assets/icons/geoloc.svg?component';
import PlusIcon from 'src/assets/icons/plus.svg?component';
import CircleSuccessIcon from 'src/assets/icons/circle-success.svg?component';
import FileFlatIcon from 'src/assets/icons/file-icon-flat.svg?component';
import EmojiIcon from 'src/assets/icons/emoji.svg?component';
import SendIcon from 'src/assets/icons/send.svg?component';
import AttachFileIcon from 'src/assets/icons/attach-file.svg?component';
import UniversityNoPhotoIcon from 'src/assets/icons/university-no-photo.svg?component';
import BestAttemptResult from 'src/assets/icons/best-attempt-star.svg?component';
import Question from 'src/assets/icons/question.svg?component';
import MarkIcon from 'src/assets/icons/mark.svg?component';
import MainIcon from 'src/assets/icons/main.svg?component';
import DivisionNoPhotoIcon from 'src/assets/icons/division-no-photo.svg?component';
import AddressIcon from 'src/assets/icons/address.svg?component';
import Calendar2Icon from 'src/assets/icons/calendar2.svg?component';
import FileSizeIcon from 'src/assets/icons/file-size.svg?component';
import ChatThreadIcon from 'src/assets/icons/chat-thread.svg?component';
import QuoteIcon from 'src/assets/icons/quote.svg?component';
import PinIcon from 'src/assets/icons/pin.svg?component';
import PinMessageIcon from 'src/assets/icons/pin-message-icon.svg?component';
import CameraIcon from 'src/assets/icons/camera.svg?component';
import DragIconLine from 'src/assets/icons/drag-icon-line.svg?component';
import GreenCloudIcon from 'src/assets/icons/green-cloud-icon.svg?component';
import RedCloudIcon from 'src/assets/icons/red-cloud-icon.svg?component';
import ExpandIcon from 'src/assets/icons/expand.svg?component';
import UnpinIcon from 'src/assets/icons/unpin.svg?component';
import AddAttemptIcon from 'src/assets/icons/add-attempt.svg?component';
import AddedAttemptIcon from 'src/assets/icons/added-attempt.svg?component';
import BriefCaseIcon from 'src/assets/icons/briefcase.svg?component';
import ReasonCloudsIcon from 'src/assets/icons/reason-clouds.svg?component';
import CartIcon from 'src/assets/icons/cart.svg?component';
import CopyLinkIcon from 'src/assets/icons/copy-link.svg?component';
import PaidIcon from 'src/assets/icons/paid.svg?component';
import HandIcon from 'src/assets/icons/hand.svg?component';
import HandUpIcon from 'src/assets/icons/hand-up.svg?component';
import HandOffIcon from 'src/assets/icons/hand-off.svg?component';
import EmailIcon from 'src/assets/icons/email.svg?component';
import ArrowSortIcon from 'src/assets/icons/arrow-sort.svg?component';
import AudioFileIcon from 'src/assets/icons/audio-file.svg?component';
import CallRecordCodingSuccess from 'src/assets/icons/call-record-coding-success.svg?component';
import CallRecordCoding from 'src/assets/icons/call-record-coding.svg?component';
import CallRecordCodingError from 'src/assets/icons/call-record-coding-error.svg?component';
import CallEndForAll from 'src/assets/icons/call-end.svg?component';
import CloseFullIcon from 'src/assets/icons/close-full.svg?component';
import SelfStudyIcon from 'src/assets/icons/selfstudy.svg?component';
import CodeTaskContentIcon from 'src/assets/icons/code-task-content.svg?component';
import FlagIcon from 'src/assets/icons/flag.svg?component';
import AllParticipants from 'src/assets/icons/all-participants.svg?component';
import ProgramPlanIcon from 'src/assets/icons/program-plan.svg?component';
import MoodleIcon from 'src/assets/icons/moodle.svg?component';
import HybridActivityIcon from 'src/assets/icons/hybrid-activity.svg?component';
import FutureCode from 'src/assets/icons/futureCode.svg?component';
import NotificationOff from 'src/assets/icons/notificationOff.svg?component';
import NotificationOn from 'src/assets/icons/notificationOn.svg?component';
import LockShieldRedIcon from 'src/assets/icons/lock-shield-red.svg?component';
import LockShieldWarningIcon from 'src/assets/icons/lock-shield-warning.svg?component';
import SortByAlphabetDownIcon from 'src/assets/icons/sort-by-alphabet-down.svg?component';
import SortByAlphabetUpIcon from 'src/assets/icons/sort-by-alphabet-up.svg?component';
import SortByTimeDownIcon from 'src/assets/icons/sort-by-time-down.svg?component';
import SortByTimeUpIcon from 'src/assets/icons/sort-by-time-up.svg?component';
import MouseIcon from 'src/assets/icons/mouse.svg?component';
import HourglassIcon from 'src/assets/icons/hourglass.svg?component';
import HideChatIcon from 'src/assets/icons/hide-chat.svg?component';
import OpenCallChatIcon from 'src/assets/icons/open-call-chat.svg?component';
import GoToIcon from 'src/assets/icons/image-goto.svg?component';
import RepairIcon from 'src/assets/icons/repair.svg?component';
import GreatIcon from 'src/assets/icons/great.svg?component';
import ForwardIcon from 'src/assets/icons/forward.svg?component';
import ScheduleIcon from 'src/assets/icons/schedule.svg?component';
import LibraryAddIcon from 'src/assets/icons/library-add.svg?component';
import TsuIcon from 'src/assets/icons/tsu.svg?component';
import NoteIcon from 'src/assets/note.svg?component';
import ParticipantsImage from 'src/assets/participants.svg?component';
import DescriptionIcon from 'src/assets/icons/description.svg?component';
import StarBlueIcon from 'src/assets/icons/star-blue.svg?component';
import ControlIcon from 'src/assets/icons/control.svg?component';
import ChatBubblesIcon from 'src/assets/icons/chat-bubbles.svg?component';
import SharingIcon from 'src/assets/icons/sharing.svg?component';
import SharingOffIcon from 'src/assets/icons/sharing-off.svg?component';
import AudioIcon from 'src/assets/icons/audio.svg?component';
import PlanetIcon from 'src/assets/icons/planet.svg?component';
import ShareIcon from 'src/assets/icons/share.svg?component';
import CodeIcon from 'src/assets/icons/code.svg?component';
import IntegrationIcon from 'src/assets/icons/integration.svg?component';
import RadioBtnIcon from 'src/assets/icons/radio-btn.svg?component';
import ReportIcon from 'src/assets/icons/report.svg?component';
import UavProjectIcon from 'src/assets/icons/uavproject.svg?component';
import AiProjectIcon from 'src/assets/icons/aiproject.svg?component';
import VolumeIcon from 'src/assets/icons/volume.svg?component';
import DeviceScreenIcon from 'src/assets/icons/device-screen.svg?component';
import DeviceCameraIcon from 'src/assets/icons/device-camera.svg?component';
import DeviceMicrophoneIcon from 'src/assets/icons/device-microphone.svg?component';
import MedalIcon from 'src/assets/icons/medal.svg?component';
import RanepaIcon from 'src/assets/icons/ranepa.svg?component';
import CursorIcon from 'src/assets/icons/cursor.svg?component';
import SpaceDiskImage from 'src/assets/space-disk.svg?component';
import AudioNoiseImage from 'src/assets/audio-noise.svg?component';
import ReportsIcon from 'src/assets/icons/reports.svg?component';
import MigrantProjectIcon from 'src/assets/icons/migrantsProject.svg?component';
import U2035MenuIcon from 'src/assets/icons/u-2035-menu-icon.svg?component';
import VolumeOffIcon from 'src/assets/icons/volume-off.svg?component';
import UploadIcon from 'src/assets/icons/upload.svg?component';
import DocumentOtherIcon from 'src/assets/icons/document-other.svg?component';


// Импортируем все иконки для удобства использования.
// Файл подключается в src/App.vue
// и эти иконки регистрируются глобально
export default {
    SettingsIcon,
    GbIcon,
    DivisionNoPhotoIcon,
    ClosedDivisionIcon,
    OdinIcon,
    OdinSquareIcon,
    BuildingIcon,
    ClockIcon,
    VisibleIcon,
    NotVisibleIcon,
    ChatIcon,
    StarIcon,
    DashboardIcon,
    OpenChatIcon,
    OpenBookIcon,
    SearchIcon,
    UserNoPhotoIcon,
    LifeRingIcon,
    ArchiveIcon,
    CheckSquareIcon,
    GradeIcon,
    PencilIcon,
    MenuIcon,
    GridIcon,
    LaptopIcon,
    LockIcon,
    OfflineActivityIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    CalendarCheckIcon,
    CohortsIcon,
    DisciplineIcon,
    GroupIcon,
    ProgramIcon,
    ProgramNoPhotoIcon,
    PeopleIcon,
    CopyIcon,
    CopyContentIcon,
    MoveDirectoryIcon,
    AddMaterialIcon,
    AddFolderIcon,
    MoveFolderIcon,
    RefreshIcon,
    ActivityPlanIcon,
    DisciplinePlanIcon,
    ProgramPlanIcon,
    DocumentIcon,
    FileIcon,
    FolderIcon,
    ImageIcon,
    PresentationIcon,
    SurveyIcon,
    TestIcon,
    TestTrainIcon,
    VideoIcon,
    NotSupportedFileIcon,
    DownloadIcon,
    WarningIcon,
    PlusIcon,
    TrashIcon,
    BankIcon,
    OpenNewIcon,
    SuccessIcon,
    EditIcon,
    AcceptCallIcon,
    DeclineCallIcon,
    AddCircleDottedIcon,
    AddCircleIcon,
    UserCheckIcon,
    DateIcon,
    DisclosureIcon,
    CloseIcon,
    Close2Icon,
    PhotoIcon,
    DeleteCircleIcon,
    CityIcon,
    LinkIcon,
    EditLineIcon,
    UnpinIcon,
    DisclosureDownIcon,
    BanIcon,
    OpenPlayIcon,
    LayoutIcon,
    YandexIcon,
    GoogleIcon,
    University2035Icon,
    VkIcon,
    HelpIcon,
    ListIcon,
    SubdivisionIcon,
    OutIcon,
    UserMinusIcon,
    UserIcon,
    UserListIcon,
    PadlockIcon,
    CommentIcon,
    CalendarIcon,
    FinishIcon,
    AnaliticsIcon,
    DragIcon,
    CheckIcon,
    FailIcon,
    TimeIcon,
    InarchiveIcon,
    UnarchiveIcon,
    ArchivedIcon,
    InActiveIcon,
    ChatMessageIcon,
    NewDottedIcon,
    FilterIcon,
    CloseCircleIcon,
    OpenFullScreenIcon,
    HeartIcon,
    ThumbsUpIcon,
    AlertErrorIcon,
    MegaphoneIcon,
    AcceptCall2Icon,
    DocIcon,
    InfoIcon,
    CircleSuccessIcon,
    FileFlatIcon,
    EmojiIcon,
    SendIcon,
    AttachFileIcon,
    UniversityNoPhotoIcon,
    BestAttemptResult,
    GeolocIcon,
    Question,
    MarkIcon,
    MainIcon,
    AddressIcon,
    Calendar2Icon,
    FileSizeIcon,
    ChatThreadIcon,
    QuoteIcon,
    PinIcon,
    PinMessageIcon,
    DragIconLine,
    CameraIcon,
    GreenCloudIcon,
    RedCloudIcon,
    AddAttemptIcon,
    AddedAttemptIcon,
    BriefCaseIcon,
    ReasonCloudsIcon,
    ExpandIcon,
    CartIcon,
    CopyLinkIcon,
    PaidIcon,
    HandIcon,
    HandUpIcon,
    HandOffIcon,
    EmailIcon,
    ArrowSortIcon,
    CallRecordCoding,
    CallRecordCodingError,
    CallRecordCodingSuccess,
    CallEndForAll,
    CloseFullIcon,
    SelfStudyIcon,
    FlagIcon,
    CodeTaskContentIcon,
    Settings2Icon,
    AllParticipants,
    MoodleIcon,
    HybridActivityIcon,
    FutureCode,
    NotificationOff,
    NotificationOn,
    LockShieldRedIcon,
    LockShieldWarningIcon,
    SortByAlphabetDownIcon,
    SortByAlphabetUpIcon,
    SortByTimeDownIcon,
    SortByTimeUpIcon,
    MouseIcon,
    HourglassIcon,
    HideChatIcon,
    OpenCallChatIcon,
    GoToIcon,
    RepairIcon,
    GreatIcon,
    ForwardIcon,
    LibraryAddIcon,
    ScheduleIcon,
    TsuIcon,
    NoteIcon,
    ParticipantsImage,
    DescriptionIcon,
    StarBlueIcon,
    ControlIcon,
    ChatBubblesIcon,
    SharingIcon,
    SharingOffIcon,
    AudioIcon,
    PlanetIcon,
    ShareIcon,
    CodeIcon,
    IntegrationIcon,
    RadioBtnIcon,
    ReportIcon,
    RanepaIcon,
    UavProjectIcon,
    AiProjectIcon,
    VolumeIcon,
    AudioFileIcon,
    DeviceScreenIcon,
    DeviceCameraIcon,
    DeviceMicrophoneIcon,
    MedalIcon,
    CursorIcon,
    SpaceDiskImage,
    AudioNoiseImage,
    ReportsIcon,
    MigrantProjectIcon,
    U2035MenuIcon,
    VolumeOffIcon,
    UploadIcon,
    DocumentOtherIcon,
};
